import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  Checklist,
  ServiceOrder,
  StepChecklist,
} from "../../../../../../types/managementServiceOrderTypes";
import { useStyles } from "./styles";
import { LOCAL_STORAGE_KEY } from "../../../../../../consts";
import { CHECKLIST_STATUS } from "../../../../../../consts/checklistSteps";
import { ModalConfirm } from "../../../../../../components/Modals/ConfirmModal";
import { useServiceOrder } from "../../../../../../hooks/useServiceOrder";
import StepValidationModal from "./stepValidation";
import { ImageModel } from "../../../../../../types/managementUserTypes";
import { SERVICE_ORDER_STATUS } from "../../../../../../consts/serviceOrderStatus";
import VehicleFormChecklistModal from "../../../../../../components/Modals/VehicleFormChecklistModal";
import { ChecklistStep } from "./step";
import {
  AnalyzeVisionProvider,
  useAnalyzeVision,
} from "../../../../../../contexts/AnalyzeVisionContext";

type ListRoutesProps = {
  checklist: Checklist;
  serviceOrder?: ServiceOrder;
  setServiceOrderData?: Dispatch<SetStateAction<ServiceOrder>>;
};

export const SectionChecklist = ({
  checklist,
  serviceOrder,
  setServiceOrderData,
}: ListRoutesProps) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<"validate" | "start" | "">("");
  const [selecteStep, setSelectedStep] = useState<StepChecklist>();
  const [validationModalTitle, setValidationModalTitle] = useState(
    "Deseja continuar com essa ação?"
  );

  const [openModal, setOpenModal] = useState(false);
  const { setCurrentStepList, currentStepList } = useAnalyzeVision();

  const { updateServiceOrder, loading, getServiceOrder, serviceOrderData } =
    useServiceOrder();

  const currentChecklist = checklist.id
    ? checklist
    : serviceOrderData.checklist;

  const userRole = localStorage.getItem(LOCAL_STORAGE_KEY.FORTALLOG_USER_ROLE);

  const isClient = userRole?.toString()?.includes("Cliente");

  const asyncGetServiceOrder = async () => {
    if (!serviceOrder?.id) {
      return;
    }

    await getServiceOrder(serviceOrder?.id);
  };

  useEffect(() => {
    asyncGetServiceOrder();
  }, []);

  const handleOpenModal = (step: StepChecklist) => {
    setShowModal(true);
    setSelectedStep(step);
  };

  const handleValidateStep = async (images: ImageModel[]) => {
    const getChecklistAndUpdate = currentChecklist?.steps.map(
      (stepItem, index) => {
        if (
          stepItem.name === selecteStep?.name &&
          selecteStep.order === stepItem.order
        ) {
          let isAutoAnalyzeContainer = false;
          let isSealAutoAnalyze = false;

          currentStepList[index]?.images.map((element) => {
            if (element.imageName == "PORTA DO CONTAINER") {
              isAutoAnalyzeContainer = true;
            }

            if (element.imageName == "FOTO DO LACRE DA PORTA") {
              isSealAutoAnalyze = true;
            }
          });

          if (isAutoAnalyzeContainer || isSealAutoAnalyze) {
            return {
              ...stepItem,
              images,
              status: CHECKLIST_STATUS.CONCLUIDO as any,
              sealCode: currentStepList[index].sealCode,
              maxGross: currentStepList[index].maxGross,
              tare: currentStepList[index].tare,
              containerCode: currentStepList[index].containerCode,
            };
          }

          return {
            ...stepItem,
            images,
            status: CHECKLIST_STATUS.CONCLUIDO as any,
          };
        }

        return stepItem;
      }
    );

    if (!serviceOrder) return;

    const OSToUpdate = {
      ...serviceOrder,
      checklist: {
        ...currentChecklist,
        steps: getChecklistAndUpdate,
      },
    };

    await updateServiceOrder(
      OSToUpdate,
      {
        success: "Estapa Atualizada com sucesso!",
        error: "Erro ao tentar fazer alteração na etapa",
      },
      true
    );

    setServiceOrderData && setServiceOrderData(OSToUpdate);

    if (!loading) {
      setShowModal(false);
      asyncGetServiceOrder();
    }
  };

  const handleStartStep = async (step: StepChecklist) => {
    const getChecklistAndUpdate = currentChecklist?.steps.map((stepItem) => {
      if (stepItem.name === step.name && step.order === stepItem.order) {
        return {
          ...stepItem,
          status: CHECKLIST_STATUS["EM ANDAMENTO"] as any,
        };
      }

      return stepItem;
    });

    if (!serviceOrder) return;

    const OSToUpdate = {
      ...serviceOrder,
      checklist: {
        ...currentChecklist,
        steps: getChecklistAndUpdate,
      },
    };

    await updateServiceOrder(
      OSToUpdate,
      {
        success: "Estapa iniciada com sucesso!",
        error: "Erro ao tentar iniciar etapa",
      },
      true
    );

    setServiceOrderData && setServiceOrderData(OSToUpdate);

    if (!loading) {
      setShowModal(false);
      asyncGetServiceOrder();
    }
  };

  useEffect(() => {
    setCurrentStepList(currentChecklist.steps);
  }, []);

  const hideStepButtons =
    serviceOrder?.status === SERVICE_ORDER_STATUS.FINISHED ||
    serviceOrder?.status === SERVICE_ORDER_STATUS.INITIAL ||
    serviceOrder?.status === SERVICE_ORDER_STATUS.CANCELLED;

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //const dataToUse = serviceOrderData.id ? serviceOrderData.checklist : checklist

  return (
    <>
      <ModalConfirm
        onOK={() => {
          if (!selecteStep) return;
          handleStartStep(selecteStep);
        }}
        isModalOpen={showModal && modalType === "start"}
        closeModal={() => setShowModal(false)}
        description={`Tem certeza de que deseja iniciar a etapa ${
          selecteStep?.name || ""
        }?`}
        loading={loading}
      />
      <StepValidationModal
        isModalOpen={showModal && modalType === "validate"}
        closeModal={() => setShowModal(false)}
        step={selecteStep}
        onOK={(data) => handleValidateStep(data)}
        files={selecteStep?.images}
        loading={loading}
        validationModalTitle={validationModalTitle}
      />
      <VehicleFormChecklistModal
        openModal={openModal}
        handleClose={handleCloseModal}
        serviceOrderData={serviceOrder as any}
        setServiceOrderData={setServiceOrderData as any}
        viewOnly={true}
      />
      <Grid item style={{ width: "100%" }}>
        <Typography className={classes.overviewSectionTitle}>
          Checklist
        </Typography>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Typography
          className={classes.viewVehicleChecklistTitle}
          onClick={() => setOpenModal(true)}
        >
          Visualizar Formulário do Veículo
        </Typography>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Typography className={classes.overviewSectionSubtitle}>
          Tipo de Checklist
        </Typography>
        <Typography className={classes.overviewSectionValue}>
          {currentChecklist.name}
        </Typography>
      </Grid>

      <div className={classes.row}>
        <Typography>Previsão do motorista de chegada ao Cliente:</Typography>
        <p>{` `}</p>
        <Typography className={classes.stepValue}>
          {currentChecklist?.forecast || ""}
        </Typography>
      </div>

      {currentChecklist.steps.map((step, index) => {
        if (!step.showToClient && isClient) {
          return;
        }

        return (
          <ChecklistStep
            step={step}
            index={index}
            handleOpenModal={handleOpenModal}
            hideStepButtons={hideStepButtons}
            setModalType={setModalType}
            isClient={isClient}
            handleChangeValidationModalTitle={(title) =>
              setValidationModalTitle(title)
            }
          />
        );
      })}
    </>
  );
};
